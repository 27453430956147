import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image/dist/src/components/hooks';
import BlogPage, { BlogTextSection, BlogTextTitle } from '../../components/BlogPage';

const HowSplintWorksPage = () => {
  const { placeholderImage }: { placeholderImage: ImageDataLike } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "blog/blog-1.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <Seo title="How Splint works" />
      <BlogPage image={placeholderImage} title={'How Splint works'} subtitle="Our FAQs" duration={'2min read'}>
        <BlogTextSection>
          So you’re somebody who really wants to be sure of something before you get involved with it? No problem! Here
          you'll find the questions we get asked most often.
        </BlogTextSection>
        <BlogTextTitle title="What are alternative investments?" />
        <BlogTextSection>
          Alternative investments are investment opportunities that have no or only a small correlation with traditional
          financial markets. Examples are luxury goods, commodities or private equity. Traditional investments would be
          shares or bonds.
        </BlogTextSection>

        <BlogTextTitle title="What’s Splint invest?" />
        <BlogTextSection>
          Our app is a platform that enables you to buy digital shares in alternative investments. We want to make this
          type of investment accessible to a broader audience. Because until now, it's mainly wealthy people who can
          afford to invest in things like barrels of whiskey or luxury watches. You can buy splints starting from as
          little as €50.
        </BlogTextSection>

        <BlogTextTitle title="What’s a Splint?" />
        <BlogTextSection>
          It seems you didn't pay much attention to our homepage, because we already explained that ;) A splint is a
          digital unit that defines the co-ownership of a physical asset. Example? A watch with a value of €10'000 is
          divided into 200 splints. Each of them has a value of €50. You buy two of them. You now own one percent of the
          watch.
        </BlogTextSection>

        <BlogTextTitle title="How safe are the assets and their value?" />
        <BlogTextSection>
          For each splint there is a real physical asset. The number of splints per physical asset is predefined and
          limited. Each asset is insured accordingly and stored under optimal conditions. So if someone was to steal or
          damage an asset, you are covered. But of course there is no guarantee for an increase in value of each asset.
        </BlogTextSection>

        <BlogTextTitle title="How do I make money with Splints?" />
        <BlogTextSection>
          There are predefined selling years for each asset. When reached, we look for the best opportunity to sell. As
          soon as the sale is completed, we distribute the money to the Splint holders. Then you know exactly how much
          the value of your Splints have increased. You can also sell your Splints to other app users in our market
          place.
        </BlogTextSection>

        <BlogTextTitle title="What are the investment costs?" />
        <BlogTextSection>
          When you sell an asset, you are charged a flat fee of 2% of the transaction volume. This includes our expenses
          for sales on the secondary market, our costs for maintaining this market and our organization of an asset sale
          at the end of the contract. There are no running costs when you invest in Splints. The variable costs for
          storage and insurance are already included in the splint price of €50. You can find all details in the
          factsheet of the respective investment.
        </BlogTextSection>
        <BlogTextSection>
          In order to open new asset classes, we charge an annual research contribution of €3 per asset class. It goes
          into our research and development fund to provide you with even better opportunities. This fee will only be
          charged at the first purchase and will not be deducted if you are inactive and decide not to purchase further
          splints.
        </BlogTextSection>
        <BlogTextSection>
          There are no deposit fees or other hidden fees with Splint Invest. There is also no withdrawal fee when you
          transfer your money back out of Splint
        </BlogTextSection>

        <BlogTextTitle title="Some more questions?" />
        <BlogTextSection>
          No problem! Transparency is very important to us, so we're happy to answer your burning questions. Just send
          us an email at <a href={'mailto:info@splintinvest.com'}>info@splintinvest.com</a> and we'll get back to you.
        </BlogTextSection>
      </BlogPage>
    </Layout>
  );
};

export default HowSplintWorksPage;
